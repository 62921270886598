<template>
    <div>
        <div class="home">

            <headers/>
             <blogs1/>
            <footers/>

        </div>
    </div>
</template>

<script>
    import headers from '@/components/header/menu.vue'
     import blogs1 from '../components/blogs1/blogs1.vue'
    import Footers from '../components/footer/footer.vue'
    export default {

        ame: 'Home',
        components: {
            headers,
            blogs1,
            Footers
        }


    }
</script>

<style lang="scss" scoped>

</style>