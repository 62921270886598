<template>
  <div class="home">
    <headers />
    <BlogsBoxes/>
    <BlogsContent/>
    <Footers />
  </div>
</template>

<script>
import headers from "@/components/header/menu.vue";
import BlogsBoxes from "../components/BlogsBoxes/BlogsBoxes.vue";
import BlogsContent from "../components/BlogsContent/BlogsContent.vue";
import Footers from "../components/footer/footer.vue";

export default {
  name: "Home",
  components: {
    headers,
    BlogsBoxes,
    BlogsContent,
    Footers,
  },
};
</script>
