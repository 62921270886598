<template>
  <section
    class="gla_section gla_image_bck gla_wht_txt gla_fixed"
    style="background-color: ##fffef8"
  >
    <!-- Over -->

    <div class="gla_over"></div>

    <div class="text-center" style="padding-top: 70px; padding-bottom: 60px">
      <!-- icon boxes -->

      <div class="gla_icon_boxes gla_partners row">
        <!-- item -->

        <div class="gla_partner_boxx">
          <a href="#" class="gla_news_block white">
            <span class="gla_news_img">
              <span class="gla_over"
                ><img alt="" title="" src="@/assets/trustimages/image15.jpg" class=""
              /></span>
            </span>

            <span class="gla_news_title">The Venue</span>
          </a>
        </div>

        <!-- item end -->

        <!-- item -->

        <div class="gla_partner_boxx">
          <a href="#" class="gla_news_block white">
            <span class="gla_news_img">
              <span class="gla_over"
                ><img alt="" title="" src="@/assets/trustimages/image14.jpg" class=""
              /></span>
            </span>

            <span class="gla_news_title">The Venue</span>
          </a>
        </div>

        <!-- item end -->

        <!-- item -->

        <div class="gla_partner_boxx">
          <a href="#" class="gla_news_block white">
            <span class="gla_news_img">
              <span class="gla_over"
                ><img alt="" title="" src="@/assets/trustimages/image10.jpg" class=""
              /></span>
            </span>

            <span class="gla_news_title">The Venue</span>
          </a>
        </div>

        <!-- item end -->

        <!-- item -->

        <div class="gla_partner_boxx">
          <a href="#" class="gla_news_block white">
            <span class="gla_news_img">
              <span class="gla_over"
                ><img alt="" title="" src="@/assets/trustimages/image12.jpg" class=""
              /></span>
            </span>

            <span class="gla_news_title">The Venue</span>
          </a>
        </div>

        <!-- item end -->

        <!-- item -->

        <div class="gla_partner_boxx">
          <a href="#" class="gla_news_block white">
            <span class="gla_news_img">
              <span class="gla_over"
                ><img alt="" title="" src="@/assets/trustimages/image5.jpg" class=""
              /></span>
            </span>
            <span class="gla_news_title">The Venue</span>
          </a>
        </div>
        <!-- item end -->
      </div>
      <!-- icon boxes end -->
    </div>
  </section>

  <!-- content  1 -->
  <section class="ls section_padding_50 table_section" style="background-color: #d8e5e9">
    <div class="containerr">
      <div class="row">
        <div class="col-sm-24 col-lg-12 text-justify">
          <h3>Service 1 Content</h3>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae, nostrum,
            cumque culpa provident aliquam commodi assumenda laudantium magnam illo
            nostrum. Donec nibh sapien, molestie quis elementum et, dim non atino ipsum.
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae, nostrum,
            cumque culpa provident aliquam commodi assumenda laudantium magnam illo
            nostrum. Donec nibh sapien, molestie quis elementum et, dim non atino ipsum.
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae, nostrum,
            cumque culpa provident aliquam commodi assumenda laudantium magnam illo
            nostrum. Donec nibh sapien, molestie quis elementum et, dim non atino ipsum..
          </p>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae, nostrum,
            cumque culpa provident aliquam commodi assumenda laudantium magnam illo
            nostrum. Donec nibh sapien, molestie quis elementum et, dim non atino ipsum.
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- content  1 end -->

  <!-- images  2 -->
  <section>
    <div class="text-center" style="padding-top: 0px; padding-bottom: 60px">
      <!-- icon boxes -->

      <div class="gla_icon_boxess gla_partners row">
        <!-- item -->

        <div class="gla_partner_boxx">
          <a href="#" class="gla_news_block white">
            <span class="gla_news_img">
              <span class="gla_over"
                ><img alt="" title="" src="@/assets/trustimages/image6.jpg" class=""
              /></span>
            </span>

            <span class="gla_news_title">The Venue</span>
          </a>
        </div>

        <!-- item end -->

        <!-- item -->

        <div class="gla_partner_boxx">
          <a href="#" class="gla_news_block white">
            <span class="gla_news_img">
              <span class="gla_over"
                ><img alt="" title="" src="@/assets/trustimages/image7.jpg" class=""
              /></span>
            </span>

            <span class="gla_news_title">The Venue</span>
          </a>
        </div>

        <!-- item end -->

        <!-- item -->

        <div class="gla_partner_boxx">
          <a href="#" class="gla_news_block white">
            <span class="gla_news_img">
              <span class="gla_over"
                ><img alt="" title="" src="@/assets/trustimages/image8.jpg" class=""
              /></span>
            </span>

            <span class="gla_news_title">The Venue</span>
          </a>
        </div>

        <!-- item end -->

        <!-- item -->

        <div class="gla_partner_boxx">
          <a href="#" class="gla_news_block white">
            <span class="gla_news_img">
              <span class="gla_over"
                ><img alt="" title="" src="@/assets/trustimages/image9.jpg" class=""
              /></span>
            </span>

            <span class="gla_news_title">The Venue</span>
          </a>
        </div>

        <!-- item end -->

        <!-- item -->

        <div class="gla_partner_boxx">
          <a href="#" class="gla_news_block white">
            <span class="gla_news_img">
              <span class="gla_over"
                ><img alt="" title="" src="@/assets/trustimages/image10.jpg" class=""
              /></span>
            </span>
            <span class="gla_news_title">The Venue</span>
          </a>
        </div>
        <!-- item end -->
      </div>
      <!-- icon boxes end -->
    </div>
  </section>
  <!-- images2 end -->

  <!-- content boxes 2-->
  <section class="ls section_padding_50 table_section" style="background-color: #d8e5e9">
    <div class="containerr">
      <div class="row">
        <div class="col-sm-24 col-lg-12 text-justify">
          <h3>Service 1 Content</h3>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae, nostrum,
            cumque culpa provident aliquam commodi assumenda laudantium magnam illo
            nostrum. Donec nibh sapien, molestie quis elementum et, dim non atino ipsum.
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae, nostrum,
            cumque culpa provident aliquam commodi assumenda laudantium magnam illo
            nostrum. Donec nibh sapien, molestie quis elementum et, dim non atino ipsum.
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae, nostrum,
            cumque culpa provident aliquam commodi assumenda laudantium magnam illo
            nostrum. Donec nibh sapien, molestie quis elementum et, dim non atino ipsum..
          </p>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae, nostrum,
            cumque culpa provident aliquam commodi assumenda laudantium magnam illo
            nostrum. Donec nibh sapien, molestie quis elementum et, dim non atino ipsum.
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- content 2 end -->

  <!-- images  3 -->
  <section>
    <div class="text-center" style="padding-top: 0px; padding-bottom: 60px">
      <!-- icon boxes -->

      <div class="gla_icon_boxess gla_partners row">
        <!-- item -->

        <div class="gla_partner_boxx">
          <a href="#" class="gla_news_block white">
            <span class="gla_news_img">
              <span class="gla_over"
                ><img alt="" title="" src="@/assets/trustimages/image11.jpg" class=""
              /></span>
            </span>

            <span class="gla_news_title">The Venue</span>
          </a>
        </div>

        <!-- item end -->

        <!-- item -->

        <div class="gla_partner_boxx">
          <a href="#" class="gla_news_block white">
            <span class="gla_news_img">
              <span class="gla_over"
                ><img alt="" title="" src="@/assets/trustimages/image12.jpg" class=""
              /></span>
            </span>

            <span class="gla_news_title">The Venue</span>
          </a>
        </div>

        <!-- item end -->

        <!-- item -->

        <div class="gla_partner_boxx">
          <a href="#" class="gla_news_block white">
            <span class="gla_news_img">
              <span class="gla_over"
                ><img alt="" title="" src="@/assets/trustimages/image13.jpg" class=""
              /></span>
            </span>

            <span class="gla_news_title">The Venue</span>
          </a>
        </div>

        <!-- item end -->

        <!-- item -->

        <div class="gla_partner_boxx">
          <a href="#" class="gla_news_block white">
            <span class="gla_news_img">
              <span class="gla_over"
                ><img alt="" title="" src="@/assets/trustimages/image14.jpg" class=""
              /></span>
            </span>

            <span class="gla_news_title">The Venue</span>
          </a>
        </div>

        <!-- item end -->

        <!-- item -->

        <div class="gla_partner_boxx">
          <a href="#" class="gla_news_block white">
            <span class="gla_news_img">
              <span class="gla_over"
                ><img alt="" title="" src="@/assets/trustimages/image15.jpg" class=""
              /></span>
            </span>
            <span class="gla_news_title">The Venue</span>
          </a>
        </div>
        <!-- item end -->
      </div>
      <!-- icon boxes end -->
    </div>
  </section>
  <!-- images3 end -->
</template>

<script>
export default {
  created() {
    setTimeout(() => {
      $(".gla_partners").owlCarousel({
        navigation: true,
        responsive: true,
        responsiveRefreshRate: 200,
        responsiveBaseElement: window,
        slideSpeed: 200,
        addClassActive: true,
        paginationSpeed: 200,
        rewindSpeed: 200,
        items: 4,
        autoPlay: true,
        autoHeight: true,
        touchDrag: true,
        navigationText: [
          '<i class="ti ti-angle-left"></i>',
          '<i class="ti ti-angle-right"></i>',
        ],
      });

      $(".gla_over").each(function () {
        var color = $(this).attr("data-color");
        var image = $(this).attr("data-image");
        var opacity = $(this).attr("data-opacity");
        var blend = $(this).attr("data-blend");
        var gradient = $(this).attr("data-gradient");
        if (gradient) {
          $(this).css("background-image", gradient);
        }
        if (color) {
          $(this).css("background-color", color);
        }
        if (image) {
          $(this).css("background-image", "url(" + image + ")");
        }
        if (opacity) {
          $(this).css("opacity", opacity);
        }
        if (blend) {
          $(this).css("mix-blend-mode", blend);
        }
      });
    }, 444);
  },
};
</script>

<style scoped>
.white {
  background: #f5f5f5;
}

.imagefitting {
  width: 100%;
  left: 0;
  margin-top: 36px;
  margin-bottom: 36px;
}

/*Partners*/
.gla_partner_boxx img {
  width: 100%;
  position: relative;
  top: 0%;
}
.gla_partner_boxx a {
  display: block;
  position: relative;
}
.gla_partner_boxx {
  padding: 0 10px;
}

.containerr {
  padding-top: 0px;
  padding-bottom: 35px;
}

.gla_icon_boxess {
  margin-top: 26px;
  margin-bottom: -30px;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}
</style>
