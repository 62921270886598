<template>
  <div>
    <!-- Slider -->
    <div
      class="gla_slider gla_image_bck gla_wht_txt gla_fixed"
      data-image="//static.showit.co/1600/PvM3HEcsQd-_S5FNWPj-1Q/76802/mansion_at_noble_lane_wedding_photographer_m_harris_studios-537.jpg"
      data-stellar-background-ratio="0.8"
      style="height: 700"
    >
      <!-- Over -->
      <div class="gla_over" data-color="#9abab6" data-opacity="0.2"></div>

      <div class="container">
        <!-- Slider Texts -->
        <div class="gla_slide_txt gla_slide_center_bottom text-center">
          <div class="gla_slide_midtitle">We're Getting Married</div>
          <div class="gla_slide_subtitle">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae, nostrum,
            cumque culpa provident aliquam commodi assumenda laudantium magnam illo
            nostrum. Donec nibh sapien, molestie quis elementum et,
          </div>
        </div>
        <!-- Slider Texts End -->
      </div>
      <!-- container end -->

      <!-- Slide Down -->
      <a class="gla_scroll_down gla_go" href="#gla_content">
        <b></b>
        Scroll
      </a>
    </div>
    <!-- Slider End -->


  </div>
</template>

<script>
export default {
  created() {
    setTimeout(() => {
      $(".gla_image_bck").each(function () {
        var image = $(this).attr("data-image");
        var gradient = $(this).attr("data-gradient");
        var color = $(this).attr("data-color");
        var blend = $(this).attr("data-blend");
        var opacity = $(this).attr("data-opacity");
        var position = $(this).attr("data-position");
        var height = $(this).attr("data-height");
        if (image) {
          $(this).css("background-image", "url(" + image + ")");
        }
        if (gradient) {
          $(this).css("background-image", gradient);
        }
        if (color) {
          $(this).css("background-color", color);
        }
        if (blend) {
          $(this).css("background-blend-mode", blend);
        }
        if (position) {
          $(this).css("background-position", position);
        }
        if (opacity) {
          $(this).css("opacity", opacity);
        }
        if (height) {
          $(this).css("height", height);
        }
      });
    }, 300);
  },
};
</script>

<style></style>
