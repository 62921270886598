<template>
    <section class="gla_section gla_image_bck gla_wht_txt gla_fixed" style="background-color: #f2f2f2;" >




        <div class="container text-center" style="padding-top: 0px; padding-bottom: 69px;">

            <div class="gla_icon_boxes gla_partners row">

                <!-- item -->

                <div class="gla_partner_box">

                    <a href="#" class="gla_news_block  white " >

                <span class="gla_news_img">

                  <span class="gla_over" :data-image="require(`@/assets/trustimages/image14.jpg`) "></span>

                </span>


                   <span class="gla_news_title"><strong>The Venue</strong></span>

              </a>

                </div>

                <!-- item end -->


                <!-- item -->

                <div class="gla_partner_box">

                    <a href="#" class="gla_news_block white ">

                <span class="gla_news_img">

                  <span class="gla_over" :data-image="require(`@/assets/trustimages/image15.jpg`) "></span>

                </span>



                   <span class="gla_news_title"><strong>The Venue</strong></span>

              </a>

                </div>

                <!-- item end -->


                <!-- item -->

                <div class="gla_partner_box">

                    <a href="#" class="gla_news_block white ">

                <span class="gla_news_img">

                  <span class="gla_over" :data-image="require(`@/assets/trustimages/image16.jpg`) "></span>

                </span>



                <span class="gla_news_title"><strong>The Venue</strong></span>



              </a>

                </div>

                <!-- item end -->


                <!-- item -->

                <div class="gla_partner_box">

                    <a href="#" class="gla_news_block white ">

                <span class="gla_news_img">

                  <span class="gla_over" :data-image="require(`@/assets/trustimages/image11.jpg`) "></span>

                </span>



                   <span class="gla_news_title"><strong>The Venue</strong></span>


              </a>

                </div>

                <!-- item end -->


                <!-- item -->

                <div class="gla_partner_box">

                    <a href="#" class="gla_news_block white ">

                <span class="gla_news_img">

                  <span class="gla_over" :data-image="require(`@/assets/trustimages/image17.jpg`) "></span>

                </span>



                   <span class="gla_news_title"><strong>The Venue</strong></span>


              </a>

                </div>

                <!-- item end -->
            </div>

            <!-- icon boxes end -->

        </div>

        <!-- container end -->

    </section>
</template>

<script>
export default {
    created() {
        setTimeout(() => {

            $(".gla_partners").owlCarousel({
                navigation: true,
                responsive: true,
                responsiveRefreshRate: 200,
                responsiveBaseElement: window,
                slideSpeed: 200,
                addClassActive: true,
                paginationSpeed: 200,
                rewindSpeed: 200,
                items: 4,
                autoPlay: true,
                autoHeight: true,
                touchDrag: true,
                navigationText: [
                    '<i class="ti ti-angle-left"></i>',
                    '<i class="ti ti-angle-right"></i>',
                ],
            });

            $('.gla_over').each(function(){
                var color = $(this).attr('data-color');
                var image = $(this).attr('data-image');
                var opacity = $(this).attr('data-opacity');
                var blend = $(this).attr('data-blend');
                var gradient = $(this).attr('data-gradient');
                if (gradient){
                    $(this).css('background-image', gradient);
                }
                if (color){
                    $(this).css('background-color', color);
                }
                if (image){
                    $(this).css('background-image', 'url('+image+')');
                }
                if (opacity){
                    $(this).css('opacity', opacity);
                }
                if (blend){
                    $(this).css('mix-blend-mode', blend);
                }
            });
        }, 444);
    },
};
</script>

<style scoped>
  .white
  {
    background: #f5f5f5;
  }

</style>
