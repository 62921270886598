<template>
  <div>
    <!-- section -->

    <section class="gla_section">
      <div class="container text-center">


        <br />

        <h3 class="gla_subtitle" style="margin-top: 0px;">
          Our Services
        </h3>

        <!-- boxes -->

        <div class="gla_icon_boxes row text-left">
          <!-- item -->

          <div class="col-md-4 col-sm-6">
            <a href="#" class="gla_news_block">
              <span class="gla_news_img">
                <span class="gla_over" :data-image="require(`@/assets/trustimages/image17.jpg`) "></span>
              </span>

              <span class="gla_news_title"
                >James & Nicola's Elegant Woodland Barn Weddin...</span
              >

              <span class="gla_news_author">Real Weddings | 10 December</span>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa similique
                porro officiis nobis nulla quidem nihil iste veniam ut sit, maiores.
              </p>
            </a>
          </div>

          <!-- item -->

          <div class="col-md-4 col-sm-6">
            <a href="#" class="gla_news_block">
              <span class="gla_news_img">
                <span class="gla_over" :data-image="require(`@/assets/trustimages/image11.jpg`) "></span>
              </span>

              <span class="gla_news_title"
                >6 Charitable Ways to Give Back on Your Weddin...</span
              >

              <span class="gla_news_author">Things we love | 10 December</span>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa similique
                porro officiis nobis nulla quidem nihil iste veniam ut sit, maiores.
              </p>
            </a>
          </div>

          <!-- item -->

          <div class="col-md-4 col-sm-6">
            <a href="#" class="gla_news_block">
              <span class="gla_news_img">
                <span class="gla_over" :data-image="require(`@/assets/trustimages/image16.jpg`) "></span>
              </span>

              <span class="gla_news_title"
                >Natalia & Xavier's Parisian Engagement Shoot</span
              >

              <span class="gla_news_author">Real Weddings | 10 December</span>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa similique
                porro officiis nobis nulla quidem nihil iste veniam ut sit, maiores.
              </p>
            </a>
          </div>

          <!-- item -->

          <div class="col-md-4 col-sm-6">
            <a href="#" class="gla_news_block">
              <span class="gla_news_img">
                <span class="gla_over" :data-image="require(`@/assets/trustimages/image15.jpg`) "></span>
              </span>

              <span class="gla_news_title"
                >Natalia & Xavier's Parisian Engagement Shoot</span
              >

              <span class="gla_news_author">Real Weddings | 10 December</span>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa similique
                porro officiis nobis nulla quidem nihil iste veniam ut sit, maiores.
              </p>
            </a>
          </div>

          <!-- item -->

          <div class="col-md-4 col-sm-6">
            <a href="#" class="gla_news_block">
              <span class="gla_news_img">
                <span class="gla_over" :data-image="require(`@/assets/trustimages/image14.jpg`) "></span>
              </span>

              <span class="gla_news_title"
                >Natalia & Xavier's Parisian Engagement Shoot</span
              >

              <span class="gla_news_author">Real Weddings | 10 December</span>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa similique
                porro officiis nobis nulla quidem nihil iste veniam ut sit, maiores.
              </p>
            </a>
          </div>

          <!-- item -->

          <div class="col-md-4 col-sm-6">
            <a href="#" class="gla_news_block">
              <span class="gla_news_img">
                <span class="gla_over" :data-image="require(`@/assets/trustimages/image13.jpg`) "></span>
              </span>

              <span class="gla_news_title"
                >Natalia & Xavier's Parisian Engagement Shoot</span
              >

              <span class="gla_news_author">Real Weddings | 10 December</span>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa similique
                porro officiis nobis nulla quidem nihil iste veniam ut sit, maiores.
              </p>
            </a>
          </div>

          <!-- item -->

          <div class="col-md-4 col-sm-6">
            <a href="#" class="gla_news_block">
              <span class="gla_news_img">
                <span class="gla_over" :data-image="require(`@/assets/trustimages/image12.jpg`) "></span>
              </span>

              <span class="gla_news_title"
                >Natalia & Xavier's Parisian Engagement Shoot</span
              >

              <span class="gla_news_author">Real Weddings | 10 December</span>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa similique
                porro officiis nobis nulla quidem nihil iste veniam ut sit, maiores.
              </p>
            </a>
          </div>

          <!-- item -->

          <div class="col-md-4 col-sm-6">
            <a href="#" class="gla_news_block">
              <span class="gla_news_img">
                <span class="gla_over" :data-image="require(`@/assets/trustimages/image11.jpg`) "></span>
              </span>

              <span class="gla_news_title"
                >Natalia & Xavier's Parisian Engagement Shoot</span
              >

              <span class="gla_news_author">Real Weddings | 10 December</span>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa similique
                porro officiis nobis nulla quidem nihil iste veniam ut sit, maiores.
              </p>
            </a>
          </div>

          <!-- item -->

          <div class="col-md-4 col-sm-6">
            <a href="#" class="gla_news_block">
              <span class="gla_news_img">
                <span class="gla_over" :data-image="require(`@/assets/trustimages/image10.jpg`) "></span>
              </span>

              <span class="gla_news_title"
                >Natalia & Xavier's Parisian Engagement Shoot</span
              >

              <span class="gla_news_author">Real Weddings | 10 December</span>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa similique
                porro officiis nobis nulla quidem nihil iste veniam ut sit, maiores.
              </p>
            </a>
          </div>
        </div>

        <!-- boxes end -->
      </div>

      <!-- container end -->
    </section>

    <!-- section end -->
  </div>
</template>

<script>
export default {
    created() {
        setTimeout(() => {

            $(".gla_partners").owlCarousel({
                navigation: true,
                responsive: true,
                responsiveRefreshRate: 200,
                responsiveBaseElement: window,
                slideSpeed: 200,
                addClassActive: true,
                paginationSpeed: 200,
                rewindSpeed: 200,
                items: 3,
                autoPlay: true,
                autoHeight: true,
                touchDrag: true,
                navigationText: [
                    '<i class="ti ti-angle-left"></i>',
                    '<i class="ti ti-angle-right"></i>',
                ],
            });

            $('.gla_over').each(function(){
                var color = $(this).attr('data-color');
                var image = $(this).attr('data-image');
                var opacity = $(this).attr('data-opacity');
                var blend = $(this).attr('data-blend');
                var gradient = $(this).attr('data-gradient');
                if (gradient){
                    $(this).css('background-image', gradient);
                }
                if (color){
                    $(this).css('background-color', color);
                }
                if (image){
                    $(this).css('background-image', 'url('+image+')');
                }
                if (opacity){
                    $(this).css('opacity', opacity);
                }
                if (blend){
                    $(this).css('mix-blend-mode', blend);
                }
            });
        }, 444);
    },
};
</script>

<style lang="scss" scoped></style>
