<template>
  <div class="home">
    <headers />
    <contactus />
  <map/>
    <footers />
  </div>
</template>

<script>
// @ is an alias to /src
import headers from "@/components/header/menu.vue";
import contactus from "../components/contactus/contactus.vue";
import Footers from "../components/footer/footer.vue";
import Map from "../components/map/map.vue";

export default {
  name: "Home",
  components: {
    headers,
    contactus,
    Footers,
    Map,
  }
};
</script>
