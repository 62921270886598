<template>
     <!-- section -->
    <section class="gla_section gla_image_bck gla_wht_txt gla_fixed image" data-stellar-background-ratio="0.8" :data-image="require(`@/assets/trustimages/image3.jpg`) " >

        <!-- Over -->
        <div class="gla_over" data-color="#282828" data-opacity="0.5"></div>


        <div class="container text-center">
            <h2>Contact Us</h2>
            <h3 class="gla_subtitle">Write</h3>

            <div class="row">
                <div class="col-md-8 col-md-push-2">
                    <form action="https://formspree.io/your@mail.com" method="POST">
                        <div class="row">
                            <div class="col-md-6">
                                <label>Your name*</label>
                                <input type="text" name="name" class="form-control form-opacity">
                            </div>
                            <div class="col-md-6">
                                <label>Your e-mail*</label>
                                <input type="text" name="email" class="form-control form-opacity">
                            </div>

                              <div class="col-sm-12">
                                <div class="Purpose">
                                      <label>Purpose*</label>
                                    <textarea placeholder="Your Message" rows="8" class="control form-control" id="purpose" name="message"></textarea>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="phone">
                                        <label>Phone Number*</label>
                                         <input type="text" placeholder="phone" name="phone" class="form-control">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="title">
                                        <input type="submit" class="btn submit" value="Send">
                                    </div>
                                </div>
                            </div>





                        </div>
                    </form>
                </div>
            </div>


        </div>
        <!-- container end -->

    </section>
    <!-- section end -->
</template>

<script>
    export default {

    }
</script>

<style >
    .gla_section {
        overflow: hidden;
        position: relative;
    }
    .gla_wht_txt {
        color: #fff;
    }
    .gla_image_bck {
        -webkit-background-size: cover;
        background-size: cover;
        background-position: center;
    }

    .gla_fixed {
        background-attachment:scroll!important;
    }

    .image {
        background-image: url(//static.showit.co/1600/PvM3HEcsQd-_S5FNWPj-1Q/76802/mansion_at_noble_lane_wedding_photographer_m_harris_studios-537.jpg);
        background-attachment: fixed;
        background-position: 50% 123.2px;
    }

    .gla_subtitle {
        margin-top: 0px;
        margin-bottom: 40px;
    }
</style>