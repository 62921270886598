<template>
  <div>
    <div class="gla_page_title gla_image_bck gla_wht_txt" data-color="#282828" style=" background-color: rgb(40, 40, 40);">
      <div class="container text-left">
        <div class="row">
          <div class="col-md-8">
            <h1 class="gla_h1_title" style="color:white;">Blog</h1>
            <h3 style="color:white;">Some Subtitle</h3>
          </div>

          <div class="col-md-4" >
            <div class="breadcrumbs" >
              <a href="#" style="color:white;">Home</a><a href="#" style="color:white;">Blog</a><span style="color:white">Blog</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Content -->
    <section id="gla_content" class="gla_content">
      <!-- section -->
      <section class="gla_section">
        <div class="container" style="padding-bottom: 0px;">
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <div class="gla_post_info">
                August 10
                <span class="slash-divider">/</span>
                <a href="#">Harold Henry</a>
                <span class="slash-divider">/</span>
                <a href="#">Web-design</a>, <a href="#">Marketing</a>
              </div>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                est laborum.
              </p>
              <blockquote>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat.
              </blockquote>
              <small>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                est laborum.
              </small>

              <br /><br />
              <ul>
                <li>Lorem ipsum dolor sit amet</li>
                <li>Consectetur adipiscing elit</li>
                <li>Integer molestie lorem at massa</li>
                <li>Facilisis in pretium nisl aliquet</li>
                <li>
                  Nulla volutpat aliquam velit
                  <ul>
                    <li>Phasellus iaculis neque</li>
                    <li>Purus sodales ultricies</li>
                    <li>Vestibulum laoreet porttitor sem</li>
                    <li>Ac tristique libero volutpat at</li>
                  </ul>
                </li>
                <li>Faucibus porta lacus fringilla vel</li>
                <li>Aenean sit amet erat nunc</li>
                <li>Eget porttitor lorem</li>
              </ul>

              <!--  Comments
              <section class="comments clearfix">
                <div class="comments-title">
                  <h3 class="title">Comments</h3>
                </div>
                <div class="comments-content">
                  <div class="answer">
                    <img class="img-comments" src="http://placehold.it/100x100" alt="" />
                    <div class="content-cmt">
                      <span class="name-cmt">Jonh Doe</span>
                      <span class="date-cmt">September 16, 2017</span>
                      <span><a href="#">Reply</a></span>
                      <p class="content-reply">
                        Quisque sed metus eu nunc gravida euismod. Vivamus consequat
                        sapien ut tempus hendrerit. Sed blandit vehicula urna sed posuere.
                        Praesent commodo,
                      </p>
                    </div>
                  </div>
                  <div class="reply">
                    <img class="img-comments" src="http://placehold.it/100x100" alt="" />
                    <div class="content-cmt">
                      <span class="name-cmt">Jonh Doe</span>
                      <span class="date-cmt">September 16, 2017</span>
                      <span><a href="#">Reply</a></span>
                      <p class="content-reply">
                        Quisque sed metus eu nunc gravida euismod. Vivamus consequat
                        sapien ut tempus hendrerit. Sed blandit vehicula urna sed posuere.
                        Praesent commodo,
                      </p>
                    </div>
                  </div>
                  <div class="answer">
                    <img class="img-comments" src="http://placehold.it/100x100" alt="" />
                    <div class="content-cmt">
                      <span class="name-cmt">Jonh Doe</span>
                      <span class="date-cmt">September 16, 2017</span>
                      <span><a href="#">Reply</a></span>
                      <p class="content-reply">
                        Quisque sed metus eu nunc gravida euismod. Vivamus consequat
                        sapien ut tempus hendrerit. Sed blandit vehicula urna sed posuere.
                        Praesent commodo,
                      </p>
                    </div>
                  </div>
                </div>
              </section>   -->

              <!-- End Comments -->
            </div>
            <!-- col end -->
          </div>
        </div>
        <!-- container end -->
      </section>
      <!-- section end -->
    </section>
    <!-- Content End -->
  </div>
</template>

<script>
export default {};
</script>

<style >
    .gla_h1_title {
        font: 900 44px Dosis, Arial;
        text-transform: uppercase;
        line-height: 1.125;
        margin-top: 0;
    }

    .gla_page_title {
        padding-bottom: 65px;
        padding-top: 147px;
        background: #eee;
        position: relative;
    }


</style>
