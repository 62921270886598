<template>
  <div class="home">
    <headers />
    <serviceslider />
    <Service1Content />
    <redirect />
    <seocontent/>
    <Footers />
  </div>
</template>

<script>
// @ is an alias to /src
import headers from "@/components/header/menu.vue";
import Serviceslider from "../components/serviceslider/serviceslider.vue";
import Service1Content from "../components/Service1Content/Service1Content.vue";
import Footers from "../components/footer/footer.vue";
import Redirect from "../components/redirect/redirect.vue";
import seocontent from "../components/seocontent/seocontent.vue";

export default {
  name: "Home",
  components: {
    headers,
    Serviceslider,
    Service1Content,
    Footers,
    Redirect,
    seocontent,


  },
};
</script>
