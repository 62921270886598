<template>
  <div>
    <div class="home">
      <headers />
      <gallerypage/>
      <footers />
    </div>
  </div>
</template>

<script>
import headers from "@/components/header/menu.vue";
import gallerypage from "../components/gallerypage/gallerypage.vue";

import Footers from "../components/footer/footer.vue";
export default {
  name: "Home",
  components: {
    headers,
    gallerypage,

    Footers,
  },
};
</script>

<style></style>
