<template>
  <div class="home">
    <headers />
    <serviceboxes />
    <content />
    <Footers />
  </div>
</template>

<script>
import headers from "@/components/header/menu.vue";
import serviceboxes from "../components/serviceboxes/serviceboxes.vue";
import Content from "../components/content/content.vue";
import Footers from "../components/footer/footer.vue";

export default {
  name: "Home",
  components: {
    headers,
    serviceboxes,
    Content,

    Footers,
  },
};
</script>
