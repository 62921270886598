<template>
    <div>
        <section class="ls section_padding_50 table_section" style="background-color: #d8e5e9;">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-lg-6 text-center">
                    <img alt="" title="" src="@/assets/trustimages/image3.jpg" class="imagefitting"></div>

                    <div class="col-sm-12 col-lg-6 text-justify">
                        <h3>Blogs Content  </h3>

                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae, nostrum, cumque culpa provident aliquam commodi assumenda laudantium magnam illo nostrum. Donec nibh sapien, molestie quis elementum et, dim non atino ipsum.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae, nostrum, cumque culpa provident aliquam commodi assumenda laudantium magnam illo nostrum. Donec nibh sapien, molestie quis elementum et, dim non atino ipsum.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae, nostrum, cumque culpa provident aliquam commodi assumenda laudantium magnam illo nostrum. Donec nibh sapien, molestie quis elementum et, dim non atino ipsum..</p>

                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae, nostrum, cumque culpa provident aliquam commodi assumenda laudantium magnam illo nostrum. Donec nibh sapien, molestie quis elementum et, dim non atino ipsum.</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="ls section_padding_50 table_section" >
            <div class="container">
                <div class="row">


                    <div class="col-sm-12 col-lg-6 text-justify">
                        <h3>Get the full fun while taking the </h3>

                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae, nostrum, cumque culpa provident aliquam commodi assumenda laudantium magnam illo nostrum. Donec nibh sapien, molestie quis elementum et, dim non atino ipsum.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae, nostrum, cumque culpa provident aliquam commodi assumenda laudantium magnam illo nostrum. Donec nibh sapien, molestie quis elementum et, dim non atino ipsum.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae, nostrum, cumque culpa provident aliquam commodi assumenda laudantium magnam illo nostrum. Donec nibh sapien, molestie quis elementum et, dim non atino ipsum..</p>

                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae, nostrum, cumque culpa provident aliquam commodi assumenda laudantium magnam illo nostrum. Donec nibh sapien, molestie quis elementum et, dim non atino ipsum.</p>
                    </div>

                     <div class="col-sm-12 col-lg-6 text-center">
                      <img alt="" title="" src="@/assets/trustimages/image3.jpg" class="imagefitting"></div>

                </div>
            </div>
        </section>

         <section class="ls section_padding_50 table_section" style="background-color: #d8e5e9;">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-lg-6 text-center">
                      <img alt="" title="" src="@/assets/trustimages/image3.jpg" class="imagefitting"></div>

                    <div class="col-sm-12 col-lg-6 text-justify">
                        <h3>Get the full fun while taking the </h3>

                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae, nostrum, cumque culpa provident aliquam commodi assumenda laudantium magnam illo nostrum. Donec nibh sapien, molestie quis elementum et, dim non atino ipsum.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae, nostrum, cumque culpa provident aliquam commodi assumenda laudantium magnam illo nostrum. Donec nibh sapien, molestie quis elementum et, dim non atino ipsum.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae, nostrum, cumque culpa provident aliquam commodi assumenda laudantium magnam illo nostrum. Donec nibh sapien, molestie quis elementum et, dim non atino ipsum..</p>

                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae, nostrum, cumque culpa provident aliquam commodi assumenda laudantium magnam illo nostrum. Donec nibh sapien, molestie quis elementum et, dim non atino ipsum.</p>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
    export default {

    }
</script>

<style >
    .imagefitting{
        width: 100%;
        left: 0;
        margin-top: 36px;
        margin-bottom: 36px;
    }
</style>